<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">{{ item.user ? item.user.full_name : '' }} <br>
                <small>MEMOIR {{ item.memoir ? item.memoir.year : '' }}</small>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <!-- ESTO VA A CAMBIAR -->
            <a
              class="btn btn-icon btn-secondary mx-50"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back "
              @click="$router.push({name: loggedUser.roles.includes('super-admin') ? 'researcher.memoir-profile.index' : 'researcher.memoir.index' })"
            ><i data-feather="chevron-left" /></a>
            <!-- ESTO VA A CAMBIAR -->
            <a
              v-if="canEdit"
              class="btn btn-icon btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Edit"
              @click="$router.push({name: 'researcher.memoir-profile.edit', params: { id: item.id }})"
            ><i data-feather="edit-3" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-sm-4">

            <div class="card">
              <div class="card-content collapse show">
                <div class="card-body">
                  <img
                    :src="icon_path"
                    :alt="item.alt_text"
                    class="img-fluid"
                  >
                  <hr>
                  <h3>{{ item.user ? item.user.full_name : '--' }}</h3>
                  <h5>{{ item.area ? item.area.name : '--' }}<br>{{ item.host_1 ? item.host_1.name : '--' }} ({{ item.host_1 ? item.host_1.code : '--' }})</h5>
                  <hr>
                  <template v-if="item.special_access">
                    <p><span class="badge bg-light-danger">Special access</span></p>
                  </template>
                  <template v-if="item.proof_access">
                    <p><span class="badge bg-light-info">Memoir proof access</span></p>
                  </template>
                  <template v-if="loggedUser.roles.includes('super-admin')">
                    <p><strong>Memoir status</strong></p>
                    <p
                      v-if="item.status"
                      v-html="item.status.status_table"
                    />
                  </template>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-8">

            <div class="card">
              <div class="card-header">
                <h3 class="cart-title">
                  <a data-action="collapse">Short biography</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content collapse show">
                <div class="card-body">
                  <div class="row">
                    <div
                      class="col"
                      v-html="item.short_biography"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header">
                <h3 class="cart-title">
                  <a data-action="collapse">Research interests</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content collapse show">
                <div class="card-body">
                  <div class="row">
                    <div
                      class="col"
                      v-html="item.research_interest"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header">
                <h3 class="cart-title">
                  <a data-action="collapse">Publications</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content collapse show">
                <div class="card-body">
                  <div
                    v-if="loading"
                    class="loading position-relative"
                  >
                    <div class="effect-1 effects" />
                    <div class="effect-2 effects" />
                    <div class="effect-3 effects" />
                  </div>
                  <div
                    v-else
                    class="row"
                  >
                    <div class="col">
                      <p
                        v-for="(publication, index) in publications"
                        :key="index"
                      >
                        <span v-html="publication.full_reference" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header">
                <h3 class="cart-title">
                  <a data-action="collapse">Other research activities</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content collapse show">
                <div class="card-body">
                  <div class="row">
                    <div
                      class="col"
                      v-html="item.other_activities"
                    />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      memoirProfileId: this.$route.params.id,
      icon_path: '',
      loading: false,
      canEdit: false,
    }
  },
  computed: {
    ...mapGetters({
      item: 'memoirsProfile/item',
      publications: 'memoirsProfile/publications',
      loggedUser: 'auth/admin',
      routeName: 'memoirsProfile/fromRouteName',
    }),
  },
  async mounted() {
    Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/memoirs/getActiveMemoir`).then(e => {
      if (e.data.profile_id || this.loggedUser.roles.includes('super-admin')) {
        this.canEdit = true
      }
    })

    if (this.memoirProfileId) {
      this.loading = true
      await this.$store.dispatch('memoirsProfile/fetchId', this.memoirProfileId)
      this.icon_path = this.item.image_url
      await this.$store.dispatch('memoirsProfile/getPublications', this.memoirProfileId)
      this.loading = false
    }

    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
}
</script>
